import { Button, Input, Form } from "antd"
import { navigate } from "gatsby"
import React, { useRef, useEffect } from "react"
import "../domain/forgot-password/save_new_password_from_email.css"
import { saveNewPasswordRequest } from "../services/user"
import { openCustomNotificationWithIcon } from "../common/notifycation"
import TopbarPure from "../components/organisms/topbar/topbarPure"
const DisplayEnterNewPassword = ({ location }) => {
  useEffect(() => {}, [])
  const ref = useRef()

  const [form] = Form.useForm()
  const handleSubmit = async (values) => {
    console.log("DATA BEFORE CLICK IS :", values.new_password)
    const params = new URLSearchParams(location.search)
    const token = params.get("token")
    const from = params.get("from")
    const result = await saveNewPasswordRequest(token, values.new_password)
    if (result.response.status === 200) {
      console.log("cong ratulation you can use new password to login again")
      openCustomNotificationWithIcon("success", "成功", "保存しました。")
      setTimeout(function () {
        if (from === "admin") {
          navigate("/login")
        } else {
          navigate("https://store.staging-mrfood.lisod.vn/login")
        }
      }, 2000)
    } else {
      console.log(
        "sorry there are some errors have been happened please try again laiter"
      )
      openCustomNotificationWithIcon(
        `error`,
        `サーバーに接続できません。`,
        `ただいま、サーバが混み合っています。しばらくしてから再度操作してください。`
      )
    }
  }
  return (
    <div>
      <TopbarPure></TopbarPure>
      <div className="forgot_password_body">
        <div className="card_input_info">
          <div className="label_header">
            <div className="title_header">パスワードの更新</div>
            <Form
              className="pt-6"
              onFinish={handleSubmit}
              onChange={() => {}}
              form={form}
              ref={ref}
            >
              <div className="mt-4 text14">
                <div>新しいパスワード</div>
                <Form.Item
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: `パスワードを入力してください。`,
                    },
                    {
                      min: 8,
                      message: `パスワードは8文字以上である必要があります。`,
                    },
                  ]}
                >
                  <Input.Password
                    className="input_field"
                    placeholder="********"
                  />
                </Form.Item>
              </div>
              <div className="mt-4 text14">
                <div>新しいパスワードの確認</div>
                <Form.Item
                  name="confirm"
                  dependencies={["new_password"]}
                  rules={[
                    {
                      required: true,
                      message: `パスワードを入力してください。`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error("入力した2つのパスワードが一致しません！")
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="input_field"
                    placeholder="********"
                  />
                </Form.Item>
              </div>
              <div className="flex justify-center" style={{ marginBottom: 80 }}>
                <Button
                  type="default"
                  style={{ backgroundColor: "#EC4D43" }}
                  className="button_submit"
                  size="large"
                  htmlType="submit"
                >
                  パスワードを更新する
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        className="flex text-grey-0 inter-base-regular justify-center"
        style={{ marginTop: "415px" }}
      >
        <span>&#183;</span>{" "}
        <a
          style={{ color: "#888888", textDecoration: "none" }}
          href="mailto:hello@mrfood.com"
        >
          Copyright © ミスターフード All Rights Reserved.
        </a>
      </div>
    </div>
  )
}

export default DisplayEnterNewPassword
